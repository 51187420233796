import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import RegistrationForm from './components/RegistrationForm/RegistrationForm';
import { InView } from 'react-intersection-observer';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link as ScrollLink } from 'react-scroll';

import { Controller, Scene } from 'react-scrollmagic';
import { Tween } from 'react-gsap';

import { useCheckMobile } from '../../hooks';

//Assets
import softserve_logo from '../../../asset/resource/softserve_logo.png';
import vericalFarmImg01 from '../../../asset/resource/vericalFarmImg01.jpg';
import vericalFarmImg02 from '../../../asset/resource/vericalFarmImg02.jpg';
import vericalFarmImg03 from '../../../asset/resource/vericalFarmImg03.jpg';
import vericalFarmImg04 from '../../../asset/resource/vericalFarmImg04.png';

//new asssets
import custom_mechatronics_image from '../../../asset/resource/custom_mechatronics_image.png';
import robotic_perception_image from '../../../asset/resource/robotic_perception_image.png';
import closed_loop_orchestration_image from '../../../asset/resource/closed_loop_orchestration_image.png';
import dashboard_readout_image from '../../../asset/resource/dashboard_readout_image.png';
import robot_cad_image_top from '../../../asset/resource/robot_cad_image_top.png';
import plant_monitoring_image from '../../../asset/resource/plant_monitoring_image.png';
import process_automation_image from '../../../asset/resource/process_automation_image.png';
import data_ingestion_image from '../../../asset/resource/data_ingestion_image.png';

const navLinks = [
  {
    id: 1,
    title: 'How it works',
    anchor: 'how-it-work',
  },
  {
    id: 2,
    title: 'How to use',
    anchor: 'how-to-use',
  },
  {
    id: 3,
    title: 'Use cases',
    anchor: 'use-cases',
  },
  {
    id: 4,
    title: 'Start growing better',
    anchor: 'challenge',
  },
  {
    id: 5,
    title: "Let's talk",
    anchor: 'contact-us',
  },
];

import './Main.scss';

const slides = [
  <div className="main__use-cases-slide" key={1}>
    <article>
      <h3>GROWTH CYCLE AUTOMATION</h3>
      <p>
        <span>
          SoftServe’s Smart Agricultural Robotics autonomously tend to plants throughout their entire growing journey —
          from planting to transplanting and harvesting
        </span>
        <br />
        <br />
        By designing the robot to monitor plants and act based on milestones, you can use our robotics to ensure that
        each step is carried out at the right time and in the right way.
        <br />
        <br />
        No matter what you are growing, our Smart Agricultural Robotics ensures that each individual plant is cared for
        correctly and harvested at the perfect moment.
      </p>
    </article>
    <figure className="main__how-to-use-image">
      <img width="100%" src={vericalFarmImg01} className="manufacturing" alt="manufacturing" />
    </figure>
  </div>,
  <div className="main__use-cases-slide" key={2}>
    <article>
      <h3>PRECISION AGRICULTURAL OPTIMIZATION</h3>
      <p>
        <span>
          Empower your workers to focus on improving the growing process by automating and collecting data on routine
          tasks such as watering and fertilizing.
        </span>
        <br />
        <br />
        Go a step further and collect data on a plant’s lifespan. You can leverage that data to deploy simulations of
        each plant to analyze the precise amount of water, light, and nutrients your crop needs. Ultimately, this helps
        you achieve the plant’s optimal size, taste, shape, and mineral composition.
        <br />
        <br />
        This reduces the costs of your farming operation while providing the best possible product and maximizing
        revenue.
      </p>
    </article>
    <figure className="main__how-to-use-image">
      <img width="100%" src={vericalFarmImg02} className="manufacturing" alt="manufacturing" />
    </figure>
  </div>,
  <div className="main__use-cases-slide" key={3}>
    <article>
      <h3>SUPPLY CHAIN REDUCTION</h3>
      <p>
        <span>
          You can use Smart Agricultural Robotics to set up vertical farms close to — or even inside — urban centers
          with high demand for top quality produce.
        </span>
        <br />
        <br />
        By establishing grow operations closer to your customers, you eliminate extended shipping times, reduce
        logistics costs, and prevent plants from being damaged during transit.
        <br />
        <br />
        That doesn’t just boost your supply chain’s resilience. It also opens up new ways to integrate growing
        operations with processing plants and reduces your reliance on agricultural land.
      </p>
    </article>
    <figure className="main__how-to-use-image">
      <img width="100%" src={vericalFarmImg03} className="healthcare" alt="healthcare" />
    </figure>
  </div>,
  <div className="main__use-cases-slide" key={4}>
    <article>
      <h3>PESTICIDE USE REDUCTION</h3>
      <p>
        <span>
          Our custom Smart Agricultural Robotics can be modified for almost any setting, including with mobility
          components for deployment in traditional outdoor farming.
        </span>
        <br />
        <br />
        By training neural networks to recognize and eliminate weeds without the use of pesticides, you can transition
        to greener farming methods to comply with new regulations. On top of that, your produce will carry an organic
        label, increasing its market value.
        <br />
        And pesticide-free weed elimination can also be coupled with other automated processes like irrigation and
        harvesting.
      </p>
    </article>
    <figure className="main__how-to-use-image">
      <img width="100%" src={vericalFarmImg04} className="supply_chain" alt="supply_chain" />
    </figure>
  </div>,
];

function Main() {
  const [blockView, setBlockView] = useState([]);
  const [imageView, setImageView] = useState([]);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);
  const [mobile, setMobile] = useState(false);
  const isMobile = useCheckMobile({ width: 767 });

  const handleViewBlocks = (inView, i) => {
    if (inView) {
      setBlockView((prevArray) => [...prevArray, (blockView[i] = true)]);
    }
  };

  const handleViewImages = (inView, i) => {
    if (inView) {
      setImageView((prevArray) => [...prevArray, (imageView[i] = true)]);
    }
  };

  useEffect(() => {
    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);

    isMobile ? setMobile(true) : setMobile(false);
  }, [isSafariBrowser, isMobile]);

  const carouselBaseProps = {
    showArrows: true,
    infiniteLoop: true,
    showThumbs: false,
    showStatus: false,
    animationHandler: 'slide',
  };

  const carouselFadeProps = !mobile && {
    animationHandler: 'fade',
  };

  const carouselDesktopProps = { ...carouselBaseProps, ...carouselFadeProps };

  return (
    <div className="main" id="home">
      <Header links={navLinks} />

      <div className="main__outter-bg">
        <div className="main__inner-bg">
          <section className="main__heading">
            <div className="main__heading-title">
              <h1>
                Deploy full-scale automation with
                <br />
                <span>smart agricultural robotics</span>
              </h1>
              <p className="main__heading-description">
                SoftServe’s Smart Agricultural Robotics fully automates time-consuming tasks such as plant monitoring,
                transplanting, and harvesting. Leveraging AI, closed-loop orchestration, and robotic perception in a
                versatile rig, our solution accelerates full-scale automation. Get optimized precision agriculture with
                our fully autonomous smart robotics.
              </p>
              <p>
                With a holistic development approach that uses generic components, your custom-built agricultural
                robotics solution is both cost-efficient and easily scalable.
                <br />
                Plus, the ability to tailor components to your needs is built into its unified hardware-software stack.
                Configure your robot to perform almost any task — including data collection to create digital twins — in
                the cloud or on-premises.
              </p>
            </div>
            <InView className="main__inview" id="trigger">
              <Controller globalSceneOptions={{ triggerHook: 'onLeave' }}>
                <Scene triggerElement="#trigger" duration={1000}>
                  {(progress) => (
                    <Tween
                      from={{
                        top: '150px',
                        right: '-15vw',
                      }}
                      to={{
                        top: '150vh',
                        right: '-120vw',
                      }}
                      delay={1500}
                      ease="Power3.easeInOut"
                      totalProgress={progress}
                      paused
                    >
                      <img
                        width="100%"
                        className="main__image-content"
                        src={robot_cad_image_top}
                        alt="robot-cad-image"
                      />
                    </Tween>
                  )}
                </Scene>
              </Controller>
            </InView>
          </section>
        </div>
      </div>

      <div className="main__wrapper">
        <section id="how-it-work" className="main__how-it-works">
          <h2>
            WHAT MAKES OUR
            <br />
            <span>
              SMART AGRICULTURAL
              <br />
              ROBOTICS
            </span>
            &nbsp;WORK?
          </h2>
          <div className="main__how-it-works-blocks">
            <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 0)} threshold={0.75}>
              <div
                className={cn(
                  'main__how-it-works-block',
                  blockView[0] && 'main__how-it-works-block-done',
                  'main__how-it-works-block_one',
                )}
              >
                <figure>
                  <img width="100%" src={custom_mechatronics_image} alt="custom_mechatronics_image" />
                </figure>
                <figcaption>
                  The principles of mature design — simplicity and reliability — make this robot efficient, robust, and
                  easily modifiable.
                  <br />
                  <br />
                  Adjustable generic components keep costs down, are quickly replaceable, and make scaling a breeze.
                  <br />
                  <br />
                  And with rapid prototype design and deployment, you can expedite your on-site testing and rollout.
                </figcaption>
              </div>
            </InView>
            <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 1)} threshold={0.75}>
              <div
                className={cn(
                  'main__how-it-works-block',
                  blockView[1] && 'main__how-it-works-block-done',
                  'main__how-it-works-block_two',
                )}
              >
                <figure>
                  <img width="100%" src={robotic_perception_image} alt="robotic_perception_image.png" />
                </figure>
                <figcaption>
                  An RGB camera takes 40 pictures per second to provide granular image data.
                  <br />
                  <br />
                  Neural networks process the camera data, identifying shapes and color patterns.
                  <br />
                  <br />
                  The modular platform can accommodate additional inputs including thermal cameras, 3D and pH scanners,
                  and infrared and moisture sensors.
                </figcaption>
              </div>
            </InView>
            <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 2)} threshold={0.75}>
              <div
                className={cn(
                  'main__how-it-works-block',
                  blockView[2] && 'main__how-it-works-block-done',
                  'main__how-it-works-block_three',
                )}
              >
                <figure>
                  <img width="100%" src={closed_loop_orchestration_image} alt="closed_loop_orchestration_image" />
                </figure>
                <figcaption>
                  ROS2 middleware running on the edge (Intel NUC) orchestrates nodes including the robot’s movement,
                  neural networks, and image acquisition.
                  <br />
                  <br />
                  Integrated sensors determine the limits of the camera’s horizontal movement.
                  <br />
                  <br />
                  The on-board computer registers the plant and provides data on its health and determines whether to
                  act (i.e., turning on a light, dispensing water, or transplanting).
                </figcaption>
              </div>
            </InView>
            <InView triggerOnce onChange={(inView) => handleViewBlocks(inView, 3)} threshold={0.75}>
              <div
                className={cn(
                  'main__how-it-works-block',
                  blockView[3] && 'main__how-it-works-block-done',
                  'main__how-it-works-block_four',
                )}
              >
                <figure>
                  <img width="100%" src={dashboard_readout_image} alt="dashboard_readout_image" />
                </figure>
                <figcaption>
                  The NN-generated images are displayed on a screen, allowing workers to access the robot’s internal
                  processes.
                  <br />
                  <br />
                  The images also include readouts with data about each plant’s health and size, which can be further
                  analyzed.
                </figcaption>
              </div>
            </InView>
          </div>
        </section>

        <section id="how-to-use" className="main__how-to-use">
          <h2>
            HOW YOU CAN USE
            <br />
            <span>
              SMART AGRICULTURAL
              <br /> ROBOTICS
            </span>
          </h2>
          <div className="main__how-to-use-blocks">
            <div className="main__how-to-use-block one">
              <article>
                <h3>PLANT MONITORING</h3>
                <p>
                  The robot’s neural networks are trained to recognize the signs of deteriorating plant health: yellow
                  leaves, wilting, mold, etc.
                  <br />
                  <br />
                  Users can also train the algorithm to track a plant’s size, a useful metric to determine the stages of
                  the growth cycle. Additional sensors can also check moisture and pH levels.
                </p>
              </article>
              <InView triggerOnce onChange={(inView) => handleViewImages(inView, 0)} threshold={0.75}>
                <figure className={cn('main__how-to-use-image', imageView[0] && 'main__how-to-use-image-done')}>
                  <img
                    width="100%"
                    src={plant_monitoring_image}
                    className="inspect_image"
                    alt="plant_monitoring_image"
                  />
                </figure>
              </InView>
            </div>

            <div className="main__how-to-use-block two">
              <article>
                <h3>PROCESSES AUTOMATION</h3>
                <p>
                  Based on the data generated during monitoring, users can program the robot to manipulate the plant’s
                  given desired parameters.
                  <br />
                  <br />
                  By augmenting the rig with bespoke hardware, you can set up a watering schedule, initiate
                  transplantation, dispense fertilizer, and harvest mature plants.
                </p>
              </article>
              <InView triggerOnce onChange={(inView) => handleViewImages(inView, 1)} threshold={0.75}>
                <figure className={cn('main__how-to-use-image', imageView[1] && 'main__how-to-use-image-done')}>
                  <img
                    width="100%"
                    src={process_automation_image}
                    className="ticket_modals"
                    alt="process_automation_image"
                  />
                </figure>
              </InView>
            </div>

            <div className="main__how-to-use-block three">
              <article>
                <h3>Data Ingestion</h3>
                <p>
                  The data from the image intake and supplementary sensors can also be stored and analyzed on-premises
                  or in the cloud.
                  <br />
                  <br />
                  This same data can be used as the foundation to create simulations of plants, allowing users to test
                  “what-if” scenarios for growth optimization.
                </p>
              </article>
              <InView triggerOnce onChange={(inView) => handleViewImages(inView, 2)} threshold={0.75}>
                <figure className={cn('main__how-to-use-image', imageView[2] && 'main__how-to-use-image-done')}>
                  <img width="100%" src={data_ingestion_image} className="remote_support" alt="data_ingestion_image" />
                </figure>
              </InView>
            </div>
          </div>
        </section>
      </div>

      <section id="use-cases" className="main__use-cases">
        <h2>
          <span className="small-text">Smart Agricultural Robotics</span>
          <br /> <span>Use Cases</span>
        </h2>
        <div className="main__use-cases-slider-wrapper">
          {mobile && React.createElement(Carousel, carouselBaseProps, slides)}
          {!mobile && React.createElement(Carousel, carouselDesktopProps, slides)}
        </div>
      </section>

      <div className="main__wrapper_challenge">
        <section id="challenge" className="main__challenge">
          <h2>
            <span>START GROWING BETTER</span>
          </h2>
          <article>
            <p>
              <span>
                Are you ready to join the next agricultural revolution? Try out our Smart Agricultural Robotics for a
                chance to win a Fresh Start Bundle from Click and Grow!
              </span>
              <br />
              <br />
              After trying our demo, you’ll receive a Sprout pencil containing high-quality, non-GMO seeds. Once you’re
              done using the pencil, just plant the lead-free stub according to the instructions and watch it grow!
              <br />
              <br />
              Your pencil will also come with a registration form to enter your name for a raffle.
              <br />
              <br />
              Every day at 5 p.m., we will select one person at random from the day’s registrants to win a Fruit &
              Veggie Kit from Click and Grow.
              <br />
              <br />
              <span className="big-text">
                That means five winners throughout Hannover Messe 2023 — come and experience the future of farming!
              </span>
              <br />
              <br />
              <span className="small-text">
                Note: Participants are limited to one entry. Winners will be contacted by email or phone, depending on
                which they provide, and can pick up their prize at our booth throughout the entirety of Hannover Messe
                2023.
              </span>
            </p>
            <div className="event-ad">
              <h3>
                Add&nbsp;
                <ScrollLink smooth spy className="event-ad__button" to={'contact-us'} duration={500}>
                  Smart Agricultural Robotics
                </ScrollLink>
                &nbsp;to your event!
              </h3>
              <p>Does your team want to explore the possibilities of full-scale robotic automation?</p>
            </div>
          </article>
        </section>
      </div>

      <section id="contact-us" className="main__contact-us-form">
        <div className="main__contact-us-form-title-col">
          <h2 className="main__contact-us-form-title">Let’s work together</h2>
        </div>
        <RegistrationForm />
      </section>

      <Footer logo={softserve_logo} />
    </div>
  );
}

export default Main;
