import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-v3';

import routes from './routes';

import './App.scss';

function App() {
  useEffect(() => loadReCaptcha(process.env.RECAPTCHA_KEY));

  const routeComponents = routes.map(({ path, component: Component }, index) => (
    <Route path={path} key={index} element={<Component />} />
  ));

  return (
    <div className="app">
      <Routes>{routeComponents}</Routes>
    </div>
  );
}

export default App;
